.member-img {
  width: 288px;
  height: 288px;
  object-fit: contain;
  /* border-radius: 50%; */
}

.member-title > p {
  text-align: justify !important;
  max-width: 600px;
}

.member-short-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* max-width: 100%; */
  width: 1200px;
}

.member-header {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: 98vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.member__img-container {
  max-width: 260px;
  /* flex-shrink: 1; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.member__info {
  min-width: 250px;
  flex-shrink: 1;
  flex: 2;
}

.about-member {
  display: flex;
  flex-wrap: wrap;
}
