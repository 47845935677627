.testimonial {
  display: flex;
  align-items: center;
  padding: 1rem;
  overflow: hidden;
}

.testimonial__img-container {
}
.testimonial__img-container img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.testimonial h3 {
  padding: 0.5rem;
  padding-left: 1.5rem;
}
