.home_bg-image {
  height: calc(100vh - 92px);
  max-height: 800px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  font-family: "Mukta", sans-serif;
}

.slider-container {
  color: black;
}

.slider-container > img {
  position: relative;
}
