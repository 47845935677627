@import url("../../App.css");

.team__profile {
  max-height: 500px;
  overflow: hidden;
}

.team__profile > img {
  object-fit: contain;
  max-height: 300px;
}

.team__profile:hover .img-wrapper ul {
  max-width: 3rem;
  background-color: white;
}

.team__profile * {
  /* background: #f1f2f6; */
  margin: 0;
  padding: 0;
}

.image-area {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.img-wrapper {
  width: 300px;
  height: 600px;
  position: relative;
  overflow: hidden;
}

.img-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 180%;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  z-index: 1;
  transform: skew(45deg);
  transition: 0.5s;
}

.img-wrapper:hover:before {
  left: -180%;
}

.img-wrapper img {
  height: 400px;
  width: 300px;
  /* filter: grayscale(0%); */
  transition: 2s;
}
.img-wrapper:hover img {
  transform: scale(1.1);
  /* filter: grayscale(100%); */
}

.img-wrapper h2 {
  font-family: Poppins;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: perspective(400px) rotateY(90deg);
  transform-origin: right;
  transition: 1s;
}

.img-wrapper:hover h2 {
  transform: perspective(400px) rotateY(0deg);
}

.img-wrapper ul {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  /* background: rgba(255, 255, 255, 0); */
  /* background-color: o; */
  max-width: 0;
  transition: all 0.3s ease;
}

.img-wrapper ul li {
  /* background: #333; */
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  transform: perspective(800px) rotateY(90deg);
  transition: 0.5s;
  transform-origin: left;
}

.img-wrapper:hover ul li {
  transform: perspective(800px) rotateY(0deg);
}

.img-wrapper:hover ul li:nth-child(1) {
  transition-delay: 0.2s;
}

.img-wrapper:hover ul li:nth-child(2) {
  transition-delay: 0.6s;
}

.img-wrapper:hover ul li:nth-child(3) {
  transition-delay: 0.8s;
}

.img-wrapper:hover ul li:nth-child(4) {
  transition-delay: 1s;
}

.img-wrapper ul li a {
  color: tomato;
  background: rgba(255, 255, 255, 0);
}

.img-wrapper ul li i {
  color: tomato;
  background: rgba(255, 255, 255, 0);
}

.img-wrapper ul li i:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0);
}

.team-img-container {
  height: 400px;
  min-width: 200px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.team-img-container:hover {
  box-shadow: 0 0 24px 0.5px #00000071;
  transform: scale(1.02);
}

.team-img-container > img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.team-title-container {
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-title {
  width: 100%;
}

.team-title > h6 {
  font-size: 14px;
}

.team-title > h5 {
  transition: all 0.3s ease-in-out;
}

.team-title > h5:hover {
  color: var(--primary-color);
}

.team-title > p {
  font-size: 12px;
  margin-top: 1rem;
}

.team-card {
  padding: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-card-container {
  justify-content: center;
  max-width: 920px;
}
.team-card-container > div {
  width: fit-content;
}
