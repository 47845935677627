@import url("../../App.css");

.services {
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

/* .services > h1 {
  margin: 0;
  padding: 0;
  border-bottom: 4px solid var(--primary-color);
  padding-bottom: 1rem;
} */

.wider-div {
  width: 100%;
  margin: 0 auto;
}

.services__section {
  min-height: 600px;
  display: flex;
  align-items: start;
}
