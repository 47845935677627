@import url("../../App.css");

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.footer__link {
  color: #565656;
  text-decoration: none;
  font-weight: 500;
  white-space: nowrap;
  transition: all 0.1s ease-out;
}

.footer__link:hover {
  color: var(--primary-color);
  transform: scale(1.05);
}

.footer-heading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

.footer-list {
  list-style: none;
  padding: 0;
  /* max-width: fit-content; */
  /* margin: 1rem auto; */
}

.footer-list li {
  margin-bottom: 10px;
}

.footer-list a {
  color: #000;
  text-decoration: none;
}

.footer-copyright {
  text-align: center;
  margin-top: 20px;
  color: #666;
}

.footer-column {
}

.footer_border-bottom {
  border-bottom: 2px solid black;
}
