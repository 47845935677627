@import url("../../App.css");

.about {
  font-size: 14px;
}

/* .about > h1 {
  margin: 0;
  padding: 0;
  border-bottom: 4px solid var(--primary-color);
  padding-bottom: 1rem;
} */

.about_text {
  text-align: justify;
  
}
