body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Mukta", sans-serif;
}

a {
  color: black;
  text-decoration: none;
}

.main-link {
  color: black;
}
.main-link:hover {
  color: rgb(175, 48, 48);
}
