.partner-link {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  max-height: 150px;
  max-width: 150px;
}

.partner-coursel > div {
  align-items: center;
}

.partner-link > img {
  max-height: 100px;
}
