:root {
  /* --primary-color: rgb(118, 118, 235); */
  --primary-color: #0095da;
  --main-color: #16459c;
}

a {
  color: black;
}

* {
  font-family: "Lato", sans-serif;
  font-family: "Mukta", sans-serif;
  font-family: "Noto Sans", sans-serif;
  font-family: "Source Serif 4", serif;
}

.App {
  text-align: center;
  padding: 0 2px;
}
.xs-text {
  font-size: 0.7rem;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-main {
  background-color: var(--primary-color);
  color: white !important;
}

.main-link {
  text-decoration: none;
  transition: all 0.1s ease-out;
}
.main-link:hover {
  color: var(--main-color);
}

.main-color {
  color: var(--primary-color) !important;
}

.secondary-color {
  color: var(--main-color) !important;
}

.custom-with {
  max-width: 1224 !important;
}

.outline-none {
  outline: none;
}
.outline-none:focus {
  outline: none !important;
}

.text-justify {
  text-align: justify;
}

.main-btn {
  border: 2px solid var(--main-color);
  padding: 0.5rem 1rem;
  font-size: 18px;
  font-weight: bold;
  color: var(--main-color) !important;
  background-color: white;
  transition: all 0.1s ease-in;

}

.main-btn:hover {
  border: 2px solid var(--main-color);
  background-color: var(--main-color);
  padding: 0.5rem 1rem;
  color: white !important;
  font-size: 18px;
  font-weight: bold;
}

.main-title-container {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row {
  margin-left: 0;
  margin-right: 0;
}
