@import url("../../App.css");

.nav_link:hover {
  color: black;
  /* border-bottom: 4px solid var(--primary-color); */
}

.nav_link {
  color: black;
}

.nav_container {
  background-color: white;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
}
/* .nav_container > div > div {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
} */

.nav_active-link {
  border-bottom: 4px solid var(--primary-color);
}
